import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ServiceCard from "../components/service-card";
import {graphql, Link, useStaticQuery} from "gatsby"
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";
import ContactForm from "../components/contact_form";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Keyboard} from "swiper";

export default function IndexPage() {

    const data = useStaticQuery(graphql`
        query GetJsonData {
            allServicesJson {
                nodes {
                    title
                    services {
                        description
                        link
                    }
                }
            }
            allCustomersJson {
                nodes {
                    name
                    logo
                    link
                }
            }
            allAppsJson {
                nodes {
                    name
                    image
                    link
                }
            }
            allFile(filter: {sourceInstanceName: {eq: "images"}}) {
                nodes {
                    childImageSharp {
                        id
                        gatsbyImageData(height: 150)
                    }
                    relativePath
                }
            }
        }
    `)

    const allServices = data.allServicesJson.nodes;
    const allCustomers = data.allCustomersJson.nodes;
    const allApps = data.allAppsJson.nodes;
    const allFile = data.allFile.nodes;

    return (
        <Layout>
            <Seo title="Home Page"/>

            <div className='sm:mt-20 mt-10'>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    autoplaySpeed={3000}
                    keyboard={{
                        enabled: true,
                    }}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: true,
                    }}
                    autoHeight={true}
                    modules={[Autoplay, Keyboard]}
                    className="mySwiper shadow-xl rounded-3xl">
                    <SwiperSlide>
                            <StaticImage
                                src='../images/appDev.png'
                                alt=''
                                placeholder='dominantColor'
                                // width={300}
                                // height={300}
                            />
                    </SwiperSlide>
                    <SwiperSlide>
                        <StaticImage
                            src='../images/metrics.png'
                            alt=''
                            placeholder='dominantColor'
                            // width={300}
                            // height={300}
                        />
                    </SwiperSlide>
                    {/*<SwiperSlide>*/}
                    {/*    <StaticImage*/}
                    {/*        src='../images/devOps.png'*/}
                    {/*        alt=''*/}
                    {/*        placeholder='dominantColor'*/}
                    {/*        // width={300}*/}
                    {/*        // height={300}*/}
                    {/*    />*/}
                    {/*</SwiperSlide>*/}
                    {/*<SwiperSlide>*/}
                    {/*    <StaticImage*/}
                    {/*        src='../images/startup.png'*/}
                    {/*        alt=''*/}
                    {/*        placeholder='dominantColor'*/}
                    {/*        // width={300}*/}
                    {/*        // height={300}*/}
                    {/*    />*/}
                    {/*</SwiperSlide>*/}
                </Swiper>
            </div>

            <div className='sm:mt-20 mt-10'>
                <h1 className='text-3xl text-center font-light mb-8'>Οι Υπηρεσίες μας</h1>
                <div className='flex flex-wrap '>
                    {allServices.map(service => (
                        <ServiceCard key={service.id} headerTitle={service.title}>
                            {service.services.map(service => (
                                <div className='pb-2' key={service.id + service.description}>
                                    <Link to={service.link}>{service.description}</Link>
                                </div>
                            ))}
                        </ServiceCard>

                    ))}
                </div>
            </div>

            <div className='sm:mt-20 mt-10'>
                <h1 className='text-3xl text-center font-light mb-8'>Οι πελάτες μας</h1>
                <div className='flex flex-wrap content-center'>
                    {allCustomers.map((customer, index) => {
                        if (allFile.filter(item => item.relativePath.indexOf(customer.logo) !== -1).length > 0) {
                            return (
                                <div key={customer.name}
                                     className={'mt-8 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3 text-center'}>
                                    <a href={customer.link} target='_blank' rel='noopener noreferrer'>
                                        <GatsbyImage
                                            image={allFile.filter(item => item.relativePath.indexOf(customer.logo) !== -1)[0].childImageSharp.gatsbyImageData}
                                            alt={customer.name}
                                            objectFit='scale-down'
                                            className='h-full'
                                        />
                                    </a>
                                </div>
                            )
                        } else {
                            return ""
                        }
                    })}
                </div>
            </div>

            <div className='sm:mt-20 mt-10'>
                <h1 className='text-3xl text-center font-light mb-8'>Μερικές από τις εφαρμογές μας</h1>
                <div className='flex flex-wrap items-center'>
                    {allApps.map((app, index) => {
                        if (allFile.filter(item => item.relativePath.indexOf(app.image) !== -1).length > 0) {
                            return (
                                <div key={app.name}
                                     className={'w-full md:w-1/2 lg:w-1/3 h-96 my-8 lg:px-4 text-center'}>
                                    <a  href={app.link} target='_blank' rel='noopener noreferrer'>
                                        <div className='w-full h-full'>
                                            <GatsbyImage
                                                image={allFile.filter(item => item.relativePath.indexOf(app.image) !== -1)[0].childImageSharp.gatsbyImageData}
                                                alt={app.name}
                                                objectFit='contain'
                                                className='w-full h-full'
                                                width={500}
                                                height={500}
                                            />
                                            <div className={'text-center text-xl italic font-bold p-2 underline'}>
                                                {app.name}
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        } else {
                            return ""
                        }
                    })}
                </div>
            </div>

            <div className='mt-20'>
                <h1 className='text-4xl text-center font-light'>Ρωτήστε μας</h1>
                <h1 className='text-2xl text-center font-light'>και θα σας απαντήσουμε άμεσα</h1>
                <h1 className='text-2xl text-center font-light'>σε οποιαδήποτε απορία
                    σας.</h1>
            </div>
            <div className='my-4'>
                <div className='w-full md:w-2/3 lg:w-1/2 mx-auto'>
                    <ContactForm/>
                </div>
            </div>
        </Layout>
    )
}
