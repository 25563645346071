import React, {useState} from 'react';
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';

const formLink = 'https://script.google.com/macros/s/AKfycbyLpBqvdsTYPb3E7fHStV1HkJogPte6FMc0a_rlsxrmHBtYhZEb6nFbWGZ0qpLm556JxQ/exec';


export default function ContactForm() {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    });
    const [statusText, setStatusText] = useState("");

    const handleChange = event => {
        const key = event.target.name;
        const updatedFormValue = event.target.value;
        const newFormData = {...formData, [key]: updatedFormValue};
        setFormData(newFormData);
    };

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleSubmit = async event => {
        event.preventDefault();
        const formDataToSubmit = {...formData};
        try {
            setFormData({
                name: "",
                email: "",
                phone: "",
                message: "",
            });
            const response = await toast.promise(
                fetch(formLink, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'text/plain;charset=utf-8',
                    },
                    body: JSON.stringify(formDataToSubmit)
                }),
                {
                    pending: 'Αποστολή...',
                    success: 'Επιτυχής αποστολή!',
                    error: 'Σφάλμα!',
                }
            );
            if (!response.ok) {
                setFormData(formDataToSubmit);
            }
        } catch (error) {
            setFormData(formDataToSubmit);
            console.log(error);
        }

    };

    return (
        <div>
            <form
                name="contact-form"
                id="contact-form"
                method="POST"
                onSubmit={e => handleSubmit(e)}
                action={formLink}
                className={'flex flex-col content-center'}
            >
                <div className="p-2 w-full">
                    <div className="relative">
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Ονοματεπώνυμο*"
                            value={formData.name}
                            onChange={e => handleChange(e)}
                            className="w-full bg-gray-100 rounded-full border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                    </div>
                </div>
                <div className="p-2 w-full">
                    <div className="relative">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="E-mail*"
                            value={formData.email}
                            onChange={e => handleChange(e)}
                            className="w-full bg-gray-100 rounded-full border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                    </div>
                </div>
                <div className="p-2 w-full">
                    <div className="relative">
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            placeholder="Τηλέφωνο Επικοινωνίας"
                            value={formData.phone}
                            onChange={e => handleChange(e)}
                            className="w-full bg-gray-100 rounded-full border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                    </div>
                </div>
                <div className="p-2 w-full">
                    <div className="relative">
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={e => handleChange(e)}
                            className="w-full bg-gray-100 rounded-3xl border border-gray-300 focus:border-indigo-500 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                            placeholder="Μήνυμα"
                            spellCheck={false}
                        ></textarea>
                    </div>
                </div>
                <div className="p-2 w-full">
                    <button
                        disabled={!formData.name || !isValidEmail(formData.email) || !formData.message}
                        type="submit"
                        name="submit"
                        className="flex mx-auto text-white disabled:cursor-not-allowed disabled:bg-gray-500 bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded-full text-lg">
                        ΑΠΟΣΤΟΛΗ
                    </button>
                </div>
            </form>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
};
